import { isArray, get } from 'lodash'

export const getFieldErrors = (name, errors): any => {
	if (!name) {
		return null
	}

	const fieldErrors = get(errors, name)

	if (fieldErrors) {
		if (!isArray(fieldErrors)) {
			return [fieldErrors]
		}
		return fieldErrors
	}

	return null
}
