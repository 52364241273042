import React, { useState, useMemo, useCallback, forwardRef } from 'react'
import clsx from 'clsx'

export const TextInput = forwardRef(
	(
		{
			name,
			placeholder,
			className,
			errors,
			dirty,
			copyable,
			value = '',
			onChange,
			...otherProps
		}: any,
		ref: any,
	) => {
		const changeHandler = useCallback(
			(e) => {
				const newValue = e.target.value

				if (onChange) {
					onChange(newValue)
				}
			},
			[onChange, value],
		)

		return (
			<input
				{...otherProps}
				type="text"
				name={name}
				ref={ref}
				placeholder={placeholder}
				value={value || ''}
				className={clsx('input', 'input--text', className, {
					'has:error': errors,
					'is:dirty': dirty,
				})}
				onChange={changeHandler}
			/>
		)
	},
)

TextInput.displayName = 'TextInput'
