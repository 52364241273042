import React, { useCallback, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useBeforeUnload } from './useBeforeUnload'

import { formatQuantity } from '@sm/client/lib'

export const useLeavePrompt = (shouldPromptLeave, dirtyCount) => {
	const router = useRouter()

	const [promptPending, setPromptPending] = useState(false)

	const onRouteChangeStart = useCallback(() => {
		if (shouldPromptLeave && !promptPending) {
			// console.debug('onRouteChangeStart')
			setPromptPending(true)
			const message = formatQuantity(
				{
					one: `Eine ungespeicherte Änderung. Seite verlassen?`,
					many: `%d ungespeicherte Änderungen. Seite verlassen?`,
				},
				dirtyCount,
			)
			if (window.confirm(message)) {
				return true
			}
			setPromptPending(false)
			throw 'Aborted navigation'
		}
	}, [shouldPromptLeave, promptPending, dirtyCount])

	useEffect(() => {
		router.events.on('routeChangeStart', onRouteChangeStart)
		return () => {
			router.events.off('routeChangeStart', onRouteChangeStart)
		}
	}, [onRouteChangeStart])

	const onBeforeUnload = useCallback(() => {
		if (shouldPromptLeave && !promptPending) {
			console.debug('onBeforeUnload')
			// setPromptPending(true)
			const message = formatQuantity(
				{
					one: `Eine ungespeicherte Änderung. Seite verlassen?`,
					many: `%d ungespeicherte Änderungen. Seite verlassen?`,
				},
				dirtyCount,
			)

			return message
			// any way to check result?
		}
	}, [shouldPromptLeave, dirtyCount])

	useBeforeUnload(onBeforeUnload, shouldPromptLeave)
}
