import React, { useEffect, useMemo, useState, useCallback } from 'react'
import {
	useForm as useHookForm,
	useFormContext,
	UseFormReturn as UseHookFormReturn,
	FieldErrors,
} from 'react-hook-form'
import { ObjectSchema } from 'yup'
import isDate from 'date-fns/isDate'
import { isObject, isArray, get, pickBy } from 'lodash'
import flat from 'flat'
import { DocumentNode } from 'graphql/index.mjs'
import { useRouter } from 'next/router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ExecutionResult } from '@apollo/react-common'
import { gql, ApolloError, FetchResult, MutationResult } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
// import { yupResolver } from '@hookform/resolvers/yup/dist/yup.umd'
import { getFieldErrors, flattenErrors } from '@sm/client/lib'
import { useBeforeUnload, useLeavePrompt } from '@sm/client/lib/hooks'
import { FormError } from '@sm/client/types'
import { formatQuantity } from '@sm/client/lib'
import { useToast, useModal, ErrorReport} from '@sm/client/components'
// import { useLeavePrompt } from '../useLeavePrompt'

export interface UseFormOptions<TData, TValues, TSubmission> {
	idProperty?: string
	schema: ObjectSchema<any>
	query?: DocumentNode
	mutation: DocumentNode
	lock?: any
	variables?: Partial<TSubmission>
	castValues?: boolean
	promptDirtyLeave?: boolean
	// createQueryData?: (dataSources: any) => TData
	createFormValues?: (data: TData) => TValues
	transformValues?: (values: TValues) => TValues
	createSubmissionVariables?: (values: TValues) => Partial<TSubmission>
	queryDataPath?: string
	mutationErrorsPath?: string
	mutationDataPath?: string
	onSubmissionResponse?: Function
	defaultValues?: any // TData // Todo fix typing, see EditEvent useEventForm call
	onError?: (error: ApolloError) => void
}

interface SubmissionResult {
	data: any
	errors: any
}

export interface UseFormReturn<TData, TValues, TSubmission>
	extends UseHookFormReturn {
	values: TValues
	data: TData
	variables: any
	errors: FormError[]
	dirtyCount: number
	errorCount: number
	isDisabled: boolean
	isLoading: boolean
	isSubmitting: boolean
	isSubmitSuccessful: boolean
	// submit: SubmitHandler<any>
	// submit: Function
	submit: (variables?: Partial<TSubmission>) => Promise<SubmissionResult>
	validate: Function
	reload: (variables?: any) => Promise<void>

	// setFormError: (message: string) => void
	// setFieldError: (name: string, message: string) => void
}

const NoOpQuery = gql`
	query {
		viewer
	}
`

// filters form data from hidden keys or invalid values

const filterSubmissionValues = (values): any => {
	const isHidden = (key, value) => {
		return key.startsWith('_') || typeof value === 'undefined'
	}

	const result = {}

	for (const [key, value] of Object.entries(values)) {
		// console.debug(key, value)

		if (isHidden(key, value)) {
			continue
		}

		if (isArray(value)) {
			result[key] = value.map((item) => {
				if (isObject(item)) {
					if (!isDate(item) && !(item instanceof File)) {
						return filterSubmissionValues(item)
					}
				}

				return item
			})
		} else if (isObject(value) && !(value instanceof File) && !isDate(value)) {
			result[key] = filterSubmissionValues(value)
		} else {
			result[key] = value
		}
	}

	return result
}

const isDOMEvent = (e: object): e is React.SyntheticEvent => {
	return (e as React.SyntheticEvent).defaultPrevented !== undefined
}

export const useForm = <TData, TValues = Partial<TData>, TSubmission = any>(
	options: UseFormOptions<TData, TValues, TSubmission>,
): UseFormReturn<TData, TValues, TSubmission> => {
	const idProperty = options?.idProperty ?? 'id'
	const hasQuery = !!options?.query
	const queryDataPath = options.queryDataPath ?? 'queryResult'
	const mutationDataPath = options.mutationDataPath ?? 'mutationResult.data'
	const mutationErrorsPath =
		options.mutationErrorsPath ?? 'mutationResult.errors'

	const [formData, setFormData] = useState<Partial<TData>>({})
	const [formValues, setFormValues] = useState<Partial<TValues>>()
	const [isLoading, setLoading] = useState<boolean>(false)
	const [isDisabled, setDisabled] = useState<boolean>(hasQuery)
	const [isSubmitSuccessful, setSubmitSuccessful] = useState<boolean>(false)
	const [isSubmitting, setSubmitting] = useState<boolean>(false)
	const [formErrors, setFormErrors] = useState<FormError[]>([])

	// !lock.acquired && !form.isLoading

	const toast = useToast()
	const modal = useModal()
	const router = useRouter()

	const form = useHookForm<any>({
		// mode: 'onBlur',
		resolver: yupResolver(options.schema),
		defaultValues: formValues,
		// shouldUnregister: true,
		shouldUnregister: false,
	})

	useEffect(() => {
		if (options.defaultValues && !hasQuery) {
			const defaultData = options.defaultValues
			const defaultFormValues = options.createFormValues
				? options.createFormValues(defaultData)
				: defaultData

			setFormData(defaultData)
			form.reset(defaultFormValues)
			setFormValues(defaultFormValues)
		}
	}, [])

	const dirtyCount = useMemo(() => {
		const dirtyFields = flat(form.formState.dirtyFields)
		// console.debug('recalc dirty', dirtyFields)

		// TODO Exclude fields beginning with _ in general?
		const skipKeys = ['__typename', '_rowKey']
		const dirtyKeys = Object.keys(dirtyFields).filter((key) => {
			const value = dirtyFields[key]
			const keyEnding = key.split('.')?.slice(-1)?.[0]

			if (!value || (isArray(value) && !value.length)) {
				return
			} else if (
				skipKeys.includes(key) ||
				(keyEnding && skipKeys.includes(keyEnding))
			) {
				return
			}
			return true
		})
		return dirtyKeys.length

		// return Object.values(dirtyFields).filter(
		// 	(v) => v && (!isArray(v) || v.length),
		// ).length
	}, [form.formState])

	// const handleMutationError = useCallback(
	// 	(error: ApolloError) => {
	// 		// console.debug('[form] handleMutationError', error, foo)
	// 		if (options.onError) {
	// 			options.onError(error)
	// 		}

	// 		// modal.error({
	// 		// 	title: 'Server Error',
	// 		// 	subtitle: 'Fehler sind aufgetreten während des Absendeprozesses.',
	// 		// 	error,
	// 		// })

	// 		// toast.error(error.toString())
	// 	},
	// 	[options.onError],
	// )

	const [sendSubmission, ] = useMutation(options.mutation)

	const handleQueryError = useCallback(
		(error: ApolloError) => {
			// console.debug('[form] handleQueryError', error, error.graphQLErrors, query)
			if (options.onError) {
				options.onError(error)
			}

			modal.error({
				title: 'Loading Error',
				subtitle: 'Fehler sind aufgetreten während des Ladeprozesses.',
				error,
				context: {
					variables: options.variables,
				},
			})

			setDisabled(true)
		},
		[options.onError, options.variables],
	)

	const query = useQuery(options?.query ?? NoOpQuery, {
		variables: options.variables,
		skip: !hasQuery,
		// https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
		fetchPolicy: 'network-only', // to disable cache
		onError: handleQueryError,
		// NOTE: onCompleted gets also called when any subentity gets updated (iE event query updates when its artist gets changed)
		// or when cache-first (default) fetchPolicy is used it gets triggered twice (once for cache hit, once for network response)
		onCompleted: (queryData) => {
			const data = get(queryData ?? query?.data, queryDataPath)

			// check if unique property changed, to enable switching of event.uid and venue.id
			if (
				formValues &&
				((!formData?.[idProperty] && !data?.[idProperty]) ||
					formData?.[idProperty] === data?.[idProperty])
			) {
				console.debug('[form] onCompleted returning')
				return
			}
			console.debug('[form] onCompleted', data)

			const loadedFormValues = options.createFormValues
				? options.createFormValues(data)
				: data

			console.debug('[form] onCompleted setFormValues', loadedFormValues)
			setFormData(data)

			const newValues = loadedFormValues

			if (dirtyCount) {
				// only set values from response if form has no changes
				const existingValues = form.getValues()
				const dirtyValues = pickBy(existingValues, (v) => v !== undefined)
				const newValues = {
					...loadedFormValues,
					...dirtyValues,
				}
				console.debug('[form] update dirty form', newValues, dirtyValues)
				// console.debug('new values', changedValues, newValues)
				form.reset(newValues, {
					keepValues: true,
					keepTouched: true,
					keepDirty: true,
				})
			} else {
				form.reset(newValues, {
					// keepDefaultValues: false,
				})
			}

			setFormValues(newValues)
			setDisabled(false)
		},
	})

	const clearErrors = useCallback(() => {
		form.clearErrors()
		setFormErrors([])
	}, [form.clearErrors])

	const validate = useCallback(() => {
		setFormErrors([])
		return form.trigger()
	}, [form.trigger])

	const reload = useCallback(
		async (variables = {}) => {
			// const { createFormValues } = options
			const res = await query.refetch({
				...options.variables,
				...variables,
			})

			// console.debug('refetch', res)
			const data = get(res.data, queryDataPath)

			const newValues = options.createFormValues
				? options.createFormValues(data)
				: data
			console.debug('[form] reload created new values', newValues)
			setFormData(data)
			setFormValues(newValues)
			form.reset(newValues)
		},
		[options.variables, query.refetch, options.createFormValues],
	)

	const submit = useCallback(
		async (submitVariables: Partial<TSubmission> = {}) => {
			const {
				// getQueryData,
				createFormValues,
				createSubmissionVariables,
				transformValues,
				onSubmissionResponse,
			} = options

			// gather data & validate
			const rawValues = form.getValues()
			const isValid = await validate()

			console.debug('[form] submission', isValid, rawValues)

			if (!isValid) {
				console.debug(
					'[form] invalid submission',
					form.formState.errors,
					rawValues,
				)
				return {
					data: null,
					errors: form.formState.errors,
				}
			}

			// set loading state

			setDisabled(true)
			setLoading(true)
			setSubmitting(true)

			// pre submission data processing

			let values = rawValues

			// let submissionValues = filterSubmissionValues(rawValues)
			// console.debug('[form] after filter', submissionValues)

			if (transformValues) {
				values = transformValues(values)
				console.debug('[form] transformedValues', values)
			}

			values = filterSubmissionValues(values)
			// console.debug('[form] after filter', values)

			if (options.schema) {
				const castValues = options.schema.cast(values)
				console.debug('[form] cast values', values, castValues)

				if (castValues) {
					values = castValues
				}
			}

			const submissionVariables: Partial<TSubmission> = createSubmissionVariables
				? createSubmissionVariables(values)
				: values

			// console.debug('hä?', submissionVariables)

			let extraVariables: Partial<TSubmission> = {}

			if (submitVariables && !isDOMEvent(submitVariables)) {
				extraVariables = submitVariables
			}

			const variables = {
				...options.variables,
				...submissionVariables,
				...extraVariables,
			} as TSubmission

			console.debug('[form] final submission', variables)

			// submit final data

			let res: FetchResult<any> = { data: null }

			try {
				res = await sendSubmission({ variables })
			} catch (err) {
				toast.error(err.toString())
				console.error(err)
				// console.debug('res is', res)
				modal.error({
					title: 'Submission Error',
					subtitle: 'Fehler sind aufgetreten während des Verarbeitungsprozesses.',
					error: err,
					context: {
						variables,
					},
				})
			}

			if (res.data) {
				console.debug('[form] submission response', res)

				const data = get(res.data, mutationDataPath)
				const errors = get(res.data, mutationErrorsPath)

				console.debug('[form] mutation data', mutationDataPath, data)
				if (errors) {
					console.debug('[form] mutation errors', mutationErrorsPath, errors)
				}

				if (onSubmissionResponse) {
					await onSubmissionResponse({
						data,
						errors,
					})
				}

				if (errors?.length) {
					const newFormErrors: FormError[] = []

					errors.forEach(({ field, message }) => {
						if (field) {
							// console.debug('setError', field, message)
							form.setError(field, {
								type: 'server',
								message,
							})
						} else if (message) {
							newFormErrors.push({
								type: 'server',
								message,
							})
							// console.debug('setError', 'global', message)
						}
						setFormErrors(newFormErrors)
					})
				}

				if (data && hasQuery) {
					const newValues = createFormValues ? createFormValues(data) : data
					console.debug('[form] post-submission setFormValues', newValues)
					setFormData(data)
					setFormValues(newValues)
					form.reset(newValues)
				}

				if (!errors?.length) {
					setSubmitSuccessful(true)
				}

				// window.requestIdleCallback(() => {
				setLoading(false)
				setSubmitting(false)
				setDisabled(false)
				// })

				return {
					data,
					errors,
				}
			}

			// window.requestIdleCallback(() => {
			setLoading(false)
			setSubmitting(false)
			setDisabled(false)
			// })

			return {
				data: null,
				errors,
			}
		},
		[form, options, validate],
	)

	const shouldPromptLeave = Boolean(
		options.promptDirtyLeave && dirtyCount && !isSubmitting,
	)

	useLeavePrompt(shouldPromptLeave, dirtyCount)

	// handle navigating away from the form by clicking a shallow nextjs link
	// https://github.com/vercel/next.js/issues/2476

	// const router = useRouter()

	// const onRouteChangeStart = useCallback(() => {
	// 	if (shouldPromptLeave) {
	// 		console.debug('onRouteChangeStart')
	// 		const message = formatQuantity(
	// 			{
	// 				one: `Eine ungespeicherte Änderung. Seite verlassen?`,
	// 				many: `%d ungespeicherte Änderungen. Seite verlassen?`,
	// 			},
	// 			dirtyCount,
	// 		)
	// 		if (window.confirm(message)) {
	// 			return true
	// 		}
	// 		throw 'Aborted navigation'
	// 	}
	// }, [shouldPromptLeave, dirtyCount])

	// useEffect(() => {
	// 	router.events.on('routeChangeStart', onRouteChangeStart)
	// 	return () => {
	// 		router.events.off('routeChangeStart', onRouteChangeStart)
	// 	}
	// }, [onRouteChangeStart])

	// handle navigating away from the form by changing the browser url

	// const onBeforeUnload = useCallback(() => {
	// 	if (shouldPromptLeave) {
	// 		console.debug('onBeforeUnload')
	// 		const message = formatQuantity(
	// 			{
	// 				one: `Eine ungespeicherte Änderung. Seite verlassen?`,
	// 				many: `%d ungespeicherte Änderungen. Seite verlassen?`,
	// 			},
	// 			dirtyCount,
	// 		)
	// 		return message
	// 	}
	// }, [shouldPromptLeave, dirtyCount])

	// useBeforeUnload(onBeforeUnload, shouldPromptLeave)

	// flatten form errors

	const errors = useMemo(() => {
		const fieldErrors = flattenErrors(form.formState.errors)
		return [...formErrors, ...fieldErrors]
	}, [formErrors, form.formState.errors])

	return {
		...form,
		variables: options.variables,
		data: formData as TData,
		values: formValues as TValues,
		errors,
		dirtyCount,
		errorCount: errors.length,
		isLoading: typeof window === 'undefined' || isLoading || query.loading,
		isDisabled,
		isSubmitting,
		isSubmitSuccessful,
		submit,
		reload,
		validate,
		clearErrors,
		// setFormError: (message: string) => {
		// 	form.setError('_global', {
		// 		type: 'manual',
		// 		message,
		// 	})
		// },
		// setFieldError: (name: string, message: string) => {
		// 	form.setError(name, {
		// 		type: 'manual',
		// 		message,
		// 	})
		// },
	}
}

interface FormFieldOptions {
	onChange?: Function
	defaultValue?: any
}

interface SetValueConfig {
	shouldValidate: boolean
	shouldDirty: boolean
}

interface FormFieldReturn extends UseHookFormReturn {
	// interface FormField {
	// form: UseFormMethods,
	dirty: boolean
	set: Function
	value?: any
	errors: FieldErrors<any>
}

export const useFormField = (
	name: string,
	options: FormFieldOptions = {},
): FormFieldReturn => {
	const form = useFormContext()
	const value = form.watch(name, options.defaultValue)

	// const value = useWatch({
	// 	name,
	// 	defaultValue: options.defaultValue,
	// })

	const errors = useMemo(() => {
		return getFieldErrors(name, form.formState.errors)
	}, [name, form.formState])

	const dirty = useMemo(() => {
		return get(form.formState.dirtyFields, name)
	}, [name, form.formState, value])

	const set = useCallback(
		(newValue, options?: SetValueConfig) => {
			if (!name.startsWith('_')) {
				console.debug('form set', name, newValue, options)
				form.setValue(name, newValue, options)
			}
		},
		[name, form.setValue],
	)

	// console.debug('useFormField', name, value)

	return {
		// form: methods,
		...form,
		value,
		// setValue,
		set,
		dirty,
		errors,
	}
}
