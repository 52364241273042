import React, { useCallback, useEffect } from 'react'

type OnBeforeUnload = (e: BeforeUnloadEvent) => string | undefined

export const useBeforeUnload = (handler: OnBeforeUnload, condition: boolean) => {
	const onBeforeUnload = useCallback(
		(e: BeforeUnloadEvent) => {
			e.preventDefault()
			const message = handler(e)

			if (message) {
				return (e.returnValue = message)
			}
		},
		[handler],
	)

	useEffect(() => {
		if (condition) {
			window.addEventListener('beforeunload', onBeforeUnload)
		} else {
			window.removeEventListener('beforeunload', onBeforeUnload)
		}
		return () => {
			window.removeEventListener('beforeunload', onBeforeUnload)
		}
	}, [onBeforeUnload, condition])
}
