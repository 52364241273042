import { isString } from 'lodash'
import { Renderable } from '@sm/client/types'
interface QuantityTemplates {
	none?: string
	one?: string
	many?: string
}

export function formatQuantity(templates: QuantityTemplates, count?: number) {
	if (!count) {
		return templates.none
	}

	if (count === 1 && templates.one) {
		// if (isString(templates.one)) {
		return templates.one.replace('%d', count.toString())
		// }
		// return templates.one
	}

	if (count > 1 && templates.many) {
		// if (isString(templates.many)) {
		return templates.many.replace('%d', count.toString())
		// }
		// return templates.many
	}
}
