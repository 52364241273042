import React, { FunctionComponent } from 'react'
import { FormProvider } from 'react-hook-form'
import clsx from 'clsx'

import { UseFormReturn} from '@sm/client/lib/hooks'

// import { ErrorList } from '@sm/client/components'
export interface FormProps extends UseFormReturn<any, any, any> {
	id?: string
	submit: any
	// data?: any
	className?: string
	children?: any
	autoComplete?: string
}

export const Form: FunctionComponent<FormProps> = ({
	id,
	children,
	submit,
	className = 'form',
	autoComplete = 'off',
	// disabled = false,
	...form
}) => {
	return (
		<FormProvider {...form}>
			<form
				id={id}
				autoComplete={autoComplete}
				onSubmit={(e) => {
					e.preventDefault()
					submit()
					// methods.handleSubmit(submit)
				}}
				className={clsx(className, {
					'has:error': form.errorCount,
					'is:dirty': form.dirtyCount,
					'is:loading': form.isLoading,
					'is:disabled': form.isDisabled,
					// 'is:loading': true,
				})}
			>
				{children}
			</form>
		</FormProvider>
	)
}
